<template>
	<div id="landTypeList">
		<page-land-type :is-shop="1"></page-land-type>
	</div>
</template>

<script>
	import pageLandType from '@/components/layout/land/page-land-type.vue'
	export default{
		components:{
			pageLandType
		},
	
	}
</script>

<style>
</style>
